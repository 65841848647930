
import { makeCompoundId } from '@/helpers';
import { ChatQuestion, Response, submitFn } from '@/ts/interfaces/Question';
import { defineComponent, PropType } from '@vue/runtime-core';
import { setLastSet, setSubmitting } from './module';

type data = { loadDots: number, status: string | null, loaderInterval: number | undefined, loaderTimeout: number | undefined }

export default defineComponent({
    props: {
        qKey: { type: Number, required: true },
        initiateSubmit: { type: Function as PropType<submitFn>, required: true },
        onProceed: { type: Function },
        onError: { type: Function }
    },
    unmounted(){
        clearInterval(this.loaderInterval);
        clearTimeout(this.loaderTimeout);
    },
    data: (): data => ({ loadDots: 0, status: null, loaderInterval: undefined, loaderTimeout: undefined }),
    computed: {
        questions(){ return this.$store.state.chat!.questions },
        lastSet(){ return this.$store.state.chat!.lastSet },
        submitting(){ return this.$store.state.chat!.submitting },
    },
    methods: {
        clearLoader(){
            clearInterval(this.loaderInterval);
            clearTimeout(this.loaderTimeout);
            this.loaderInterval = this.loaderTimeout = undefined;
            this.loadDots = 0;
            setSubmitting();
        },
        loaderSequence(){
            this.loaderInterval = setInterval(() => this.loadDots = this.loadDots < 3 ? ++this.loadDots : 1, 1000) as unknown as number;

            this.loaderTimeout = setTimeout(() => {
                if(this.status && ['error', 'redirected'].includes(this.status) && this.loaderInterval)
                    return this.clearLoader();

                this.status = 'taking-longer';
                this.onError?.('Submit is taking longer than expected.');

                this.loaderTimeout = setTimeout(() => {           
                    this.clearLoader();
                    this.status = 'try-again';
                    this.onError?.('Submit still not finished.');
                }, 4000) as unknown as number;
            }, 4000) as unknown as number;
        },
        meetsCondition({ condition, tree_path }: ChatQuestion = {} as ChatQuestion){
            if(typeof tree_path === 'undefined') return true;

            const parent = this.questions.find(q => tree_path === makeCompoundId(q));

            if(parent?.show && (typeof parent.val !== 'string' || parent.val.length))
                for(const c of condition)
                    if(this.checkCondition(parent, c))
                        return true;
                    
            return false;
        },
        checkCondition: function({ val, selected }: ChatQuestion, c: string | number){
            if(typeof c === 'number' || Number.isInteger(c))
                return typeof selected === 'number' ? val === c : selected?.[c] || false;

            let comparison = c.match(/([><]{0,2}=?)/g)!.join('');
            const [cMax, cVal] = c.split(comparison);
            switch(comparison){
                case '=':  return val === cVal;
                case '>':  return val > cVal;
                case '<':  return val < cVal;
                case '>=': return val >= cVal;
                case '<=': return val <= cVal;
                case '>>': return cMax > val && val > cVal;
            }
        },
        proceed(){
            let { questions, lastSet } = this;
            Object.assign(questions[lastSet], { show: 1, mark: 1 });
            for(let i = lastSet + 1; i < questions.length; i++){
                const question = questions[i];
                if(typeof question.show === 'undefined' || question.mark || !this.meetsCondition(question))
                    continue;

                this.onProceed?.(this.getResponses());
                question.show = true;
                setLastSet(i);
                return setTimeout(() => this.scrollPage(), 50);
            }

            this.doSubmit();
        },
        scrollPage(){
            const page = document.querySelector('main');
            const elemPos = document.querySelector(`#question${this.lastSet}`)?.getBoundingClientRect().top;
            const offset = document.querySelector('nav')?.getBoundingClientRect().height ?? 0;
            if(typeof elemPos !== 'undefined')
                page?.scrollTo({top: elemPos + page.scrollTop - offset - 100, behavior: 'smooth'});
        },
        getResponses(){
            const responses = [] as Response[];
            for(const q of this.questions) if(q.show){
                if((q.id as unknown as string).startsWith?.('l')) continue;

                const value = q.type !== 'date' || !q.altVal ? q.val : 'do_not_remember';
                responses.push({ question_id: q.id, value, tree_path: q.tree_path ?? null });
            }
            return responses;
        },
        async doSubmit(){
            if(this.status && !['try-again', 'error'].includes(this.status))
                return;

            this.status = 'submitted';
            this.loaderSequence();
            try{
                await this.initiateSubmit(this.getResponses());
                this.status = 'redirected';
            }catch(error){
                this.status = 'error';
                this.clearLoader();
                this.onError?.(error);
            }
        },
    }
});
